import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { HotelDetailsModalFooter } from './HotelDetailsModalFooter';
import { useHdpModalUrlUpdate } from './useHdpModalUrlUpdate';
import { usePandaPageUrl } from '../usePandaPageUrl';
import { DynamicPackageResult } from '@AuroraTypes';
import { HotelDetailsModal } from '@Components/HotelDetails/HotelDetailsModal';
import { HotelDetailsTabId } from '@Components/HotelDetails/HotelDetailsTypes';
import { type ModalEvent, useModal } from '@Components/Modal/useModal';
import { getHashParams } from '@Core/helpers/url';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { useSearchResultsStore, useSearchSelectionStore } from '@Stores/StoreContext';

export const HotelDeeplinkModal: React.FC = () => {
  const { hash } = useLocation();
  const { hotelUrl, hotelTab, hotelMasterId } = getHashParams(hash);
  const [isModalOpen, setModalOpen, setModalClose] = useModal(!!hotelUrl);
  const { removeUrlHashParams } = useHdpModalUrlUpdate();
  const { getPandaUrlForOffer } = usePandaPageUrl();
  const isFancyPanda = useFeatureFlag('FancyPanda');
  const history = useHistory();

  const [fetching, offers] = useSearchResultsStore((state) => [
    state.fetching,
    [...state.results, ...state.pinnedResults.map(({ offer }) => offer!).filter(Boolean)].filter(
      (r) => !!(r as DynamicPackageResult).hotel,
    ) as DynamicPackageResult[],
  ]);

  const [departureAirports, filters, nights, rooms] = useSearchSelectionStore((state) => [
    state.departureAirports,
    state.filters,
    state.nights,
    state.rooms,
  ]);

  useEffect(() => {
    if (hotelUrl) {
      setModalOpen();
    } else {
      setModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelUrl]);

  const onClose = useCallback<(event?: ModalEvent | undefined) => void>((e) => {
    removeUrlHashParams();
    setModalClose(e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isModalOpen || fetching) {
    return null;
  }

  const offer = offers.find(
    (offer) =>
      (hotelUrl && offer.hotel.accommodation.urls.seo === hotelUrl) ||
      (hotelMasterId && offer.hotel.accommodation.id === hotelMasterId),
  );

  if (!offer && !hotelUrl) {
    return null;
  }

  if (offer && isFancyPanda) {
    return null;
  }

  if (offer) {
    const pandaUrl = getPandaUrlForOffer(offer, {
      filters,
      departureAirports,
      nights,
      rooms,
    });

    if (isFancyPanda && typeof window !== 'undefined') {
      history.replace(pandaUrl);
    }

    return (
      <HotelDetailsModal
        data-id="hotel-details-modal"
        onClose={onClose as () => void}
        masterId={offer.hotel.accommodation.id}
        selectedTabId={hotelTab as HotelDetailsTabId}
        Actions={
          <HotelDetailsModalFooter
            accommodation={offer.hotel.accommodation}
            offer={offer}
            actionType="SearchResults"
            pandaUrl={pandaUrl}
          />
        }
        trackingAction="srp-deeplink-hdp-modal"
      />
    );
  }

  return (
    <HotelDetailsModal
      data-id="hotel-details-modal"
      onClose={onClose as () => void}
      seoUrl={hotelUrl}
      trackingAction="srp-deeplink-hdp-modal"
      showCtas
    />
  );
};
